<template>
  <div id="expenses-landing" class="page-owners-associations-overview">
    <section id="nav" class="globalnav-desktop">
      <div class="container-fluid">
        <nav class="navbar navbar-dark">

          <router-link class="navbar-brand" to="/">
            <img src="@/assets/img/idata-alt.svg" class="logo" alt="">
          </router-link>
          <ul class="nav" v-if="user.isIdataUser">
            <li class="nav-item nav-item-account">
              <router-link class="nav-link" to="/ingresar">
                <img src="@/assets/img/icons/account-white.svg" class="icon" alt="">
                <span>Ingresar</span>
              </router-link>
            </li>
          </ul>
        </nav>
      </div>
    </section>
    <default-modal v-if="showModal" style="z-index: 10000">
      <div slot="header">
        <div class="col-12" style="padding: 2rem 1rem">
          <h4>Realizar pago</h4>
        </div>
      </div>
      <div slot="body">
        <div>
          <h5>
            Serás redirigido a Expensas Pagas.<br /><br />
            Luego de realizar la transaccion, haz click en el botón de "Volver a I-data" para
            asentar tu pago en nuestra base de datos.
          </h5>
        </div>
      </div>
      <template slot="footer">
        <div style="width: 100%;    display: flex;    justify-content: space-between;">
          <button class="btn btn-rounded btn-primary text-center" @click="showModal = false; sendPay()">Redirigeme a Expensas Pagas</button>
          <button class="btn btn-rounded btn-outline-primary value ml-3" @click="showModal = false; ">Cerrar</button>
        </div>
      </template>
    </default-modal>
    <default-modal v-if="showModalError" style="z-index: 10000">
      <div slot="header">
        <div class="col-12" style="padding: 2rem 1rem">
          <h4>Error</h4>
        </div>
      </div>
      <div slot="body">
        <div>
          <h5>
            Ocurio un error al realizar el pago<br /><br />
            Intenta nuevamente o comunicate con soporte.
          </h5>
        </div>
      </div>
      <template slot="footer" >
        <div style="float:right">
          <button class="btn btn-rounded btn-primary text-center" @click="closeModalError()">Cerrar</button>
        </div>
      </template>
    </default-modal>
    <section id="header">
      <header>
        <div class="container-fluid">
          <section class="header">
            <h1 class="titlex text-center header-text">
              Te acercamos tu última liquidación de expensas

            </h1>
          </section>
        </div>
      </header>
    </section>
    <div class="container" v-if="isValidLink">
      <section id="owners-association-details">
        <h2 id="owners-association-title-landing" class="text-center">Consorcio</h2>
        <div class="card owners-associations-card shadow">
          <vue-element-loading :active="!period.title" spinner="spinner" color="#CE4169" />
          <div class="text-center owners-association-data">
            <h5 class="owners-associations-name-landing">{{ ownersAssociation.name }}</h5>
            <p class="owners-associations-address-landing">
              <span class="owners-associations-address-street">{{ ownersAssociation.address }}</span>,
              <span class="owners-associations-city">{{ ownersAssociation.city }}</span>
              (<span class="owners-associations-postal-code">{{ ownersAssociation.postal_code }}</span>)
            </p>
          </div>
        </div>
      </section>
      <section id="expenses-details">
        <div class="card settlement-card shadow">
          <vue-element-loading :active="!period.title || isLoading" spinner="spinner" color="#CE4169" />
          <div id="settlement-header">
            <div class="featured">
              <p class="section-header" v-if="period.period_id === periodUltimo.period_id">
                Última liquidación de expensas de la U.F {{this.period.functional_units[0].location}}
              </p>

              <div class="separator" v-if="period.period_id === periodUltimo.period_id"></div>
              <dropdown-selector @selected="changePeriod" :options="periods" :default="period"></dropdown-selector>
            </div>

           <!-- v-if="ownersAssociation.expense_payment_method_ids.length>0 && is_with_in_limit_date===false&&period.period_id === periodUltimo.period_id"-->
            <div  v-if="ownersAssociation.expense_payment_method_ids && ownersAssociation.expense_payment_method_ids.length>0 && period.is_with_in_limit_date===false&&period.period_id === periodUltimo.period_id"
                  class="alert alert-danger table" role="alert">Este periodo se encuentra vencido, ya no se puede abonar online</div>

            <div id="city">
              <img :src="city_image.img_normal" :srcset="city_image.img_2x + ' 2x'"
                class="owners-association-city-image" />
            </div>
          </div>
          <section class="settlement-overview">
            <div class="data">
              <div v-if="period.due_date" class="settlement-due-date">
                <div class="value">{{ period.due_date | moment('DD/MM/YY') }}</div>
                <div class="label">Vencimiento</div>
              </div>
              <div class="settlement-amount">
                <div class="value">{{ period.total_amount | currency }}</div>
                <div class="label">Monto total</div>
              </div>
            </div>
            <div class="actions_separator">
            </div>
            <div id="settlement-actions" class="data_actions">
              <vue-element-loading :active="isDownloadingDocuments" color="#CE4169" spinner="spinner" />
              <div id="download_documents" class="actions">
                <button class="btn btnlanding btn-rounded btn-outline-primary value" href="javascript:"
                  @click="getAnnexDocuments();">
                  Descargar gastos y anexos
                </button>
              </div>
              <div id="download_settlements" class="actions">
                <button class="btn btnlanding btn-rounded btn-outline-primary value" href="javascript:"
                  @click="getPaymentsDocuments();">
                  Descargar Talón de pago
                </button>
              </div>

              <!---->
              <div id="view_invoices" class="actions" v-if="invoices_show_type != 0">
                <button class="btn btnlanding btn-rounded btn-outline-primary value" href="javascript:"
                  @click="resolveRedirect()">
                  Ver comprobantes
                </button>
              </div>
            <div id="pay" class="actions"
                 v-if="ownersAssociation.expense_payment_method_ids && ownersAssociation.expense_payment_method_ids.length>0 && period.period_id === periodUltimo.period_id"
            >

                <button class="btn btnlanding btn-rounded btn-outline-primary value" href="javascript:"
                   @click="openModal()" :disabled="period.is_with_in_limit_date===false||period.total_amount==0 ? true : false">
                 Pago online
                </button>

            </div>

            </div>
          </section>
        </div>
      </section>
    </div>
    <div class="container" v-else>
      <section id="expenses-details">
        <div class="card settlement-card shadow">
          <div class="empty-state empty-state-documents-date" style="margin-top:5rem !important"
            id="no-documents-message">
            <div class="wrapper">
              <h4>No hay datos disponibles</h4>

              <img src="@/assets/img/empty-states/no-hay-documentos.png" class="image-empty" alt="">
            </div>
          </div>
        </div>
      </section>
    </div>

    <section class="administration theme-fuchsia" />
    <section class="administration theme-white" v-if="user.isIdataUser">
      <h1 id="footer-login-text" class="text-center">¿Quieres ver toda la información de tu propiedad?</h1>
      <div id="footer-login-btn" class="actions text-center">
      <span style="color:white"> Para ver mas datos de tu unidad funcional ingresa en </span>
        <router-link to="/ingresar" class="btn btn-rounded btn-primary value">
          ingresar
        </router-link>
      </div>
    </section>
    <footer id="footer" class="container-fluid footer">
      <span style="color:white"> Para ver mas datos de tu unidad funcional ingresa en </span>
      <router-link class="navbar-brand" to="/">
        <img src="@/assets/img/idata-alt.svg" class="logo" alt="">
      </router-link>
    </footer>
  </div>
</template>

<script>
import axios from 'axios';
import { Capacitor } from '@capacitor/core';
import { InAppBrowser } from '@ionic-native/in-app-browser';
import DropdownSelector from '@/components/Layouts/DropdownSelector';
import utils from '@/mixins/utils';
import store from '@/store';
import DefaultModal from '@/components/Modal/DefaultModal';

export default {
  name: 'ExpensesLanding',
  props: ['referrer'],
  components:
  {
    DropdownSelector,
    DefaultModal,
  },
  mixins:
    [
      utils,
    ],
  data() {
    return {
      userId: this.$route.params.id,
      user: {},
      ownersAssociationId: this.$route.params.ownersAssociation,
      isLoading: false,
      isDownloadingDocuments: false,
      ownersAssociation: {},
      period: {},
      showModal: false,
      showModalError: false,
      periodUltimo: {},
      allPeriods: {},
      invoices_delay: 15,
      invoices_show_type: 0,
      isValidLink: true,
    };
  },
  methods: {
    async getPaymentsDocuments() {
      this.isDownloadingDocuments = true;
      const documents = this._.filter(this.period.period_documents, { type: 'Avisos de pago' });
      await this.downloadPeriodDocuments(documents);
    },
    async redirect(name, params, query = null) {

      await this.$router.push({ name, params, query });

    },
    async getAnnexDocuments() {
      this.isDownloadingDocuments = true;
      const liquidaciones = this._.filter(this.period.period_documents, { type: 'Liquidaciones de gastos' });
      const anexos = this._.filter(this.period.period_documents, { type: 'Anexos' });
      const documents = this._.concat(liquidaciones, anexos);
      await this.downloadPeriodDocuments(documents);
    },

    openModal() {
      if (!this.loading && this.total_amount !== 0) this.showModal = true;
    },
    async downloadPeriodDocuments(documents) {
      const parentData = this;
      if (documents.length === 1) {
        parentData.isDownloadingDocuments = false;
        window.location.href = documents[0].url;
      } else if (documents.length > 1) {
        try {
          const inputDocs = documents.map((d) => d.url);
          axios.post(process.env.VUE_APP_PDF_SERVICE_URL,
            {
              pdfs_urls: inputDocs,
            })
            .then((res) => {
              parentData.isDownloadingDocuments = false;
              window.location.href = res.data.url;
            });

          /*
          this.mergeDocuments(inputDocs).then((res) => {
            parentData.isDownloadingDocuments = false;
            window.location.href = res.url;
          }); */
        } catch (err) {
          console.log(err);
          this.$swal({
            type: 'error',
            title: 'Lo sentimos...',
            text: 'No se puede descargar el archivo en estos momentos. Intente nuevamente mas tarde.',
            onAfterClose: () => this.$router.push(`/consorcios/${this.owners_association.id}`),
          });
        }
      }
    },

    async sendPay() {
     this.loading = true;

        const details = [{
            cpe: this.period.functional_units[0].cpe,
            monto: parseFloat(this.period.amount_with_service_fee),

          }];
        
      console.log(details);
      console.log(JSON.stringify(this.period.period_id));
        const data = {
          email: '',
          idReferenciaOperacionComercio: '',
          period_id: parseInt(this.period.period_id, 10),
          detalle: details,
        };
      console.log(`${process.env.VUE_APP_CORE_SERVICE_URL}/owners_associations/${this.ownersAssociationId}/aws_user_id/${this.userId}/expense_payments/multiplePaymentIntentsByAwsUserId`);
          await axios.post(
                  `${process.env.VUE_APP_CORE_SERVICE_URL}/owners_associations/${this.ownersAssociationId}/aws_user_id/${this.userId}/expense_payments/multiplePaymentIntentsByAwsUserId`,
                   data,
                   {
 headers:
                            {
                              Accept: 'application/json',
                              'Content-Type': 'application/json',
                            },
                            },
                 
          ).then(async (result) => {

            if (result.data.data.hash) {
            
              const url = result.data.data.url + result.data.data.hash;
            
              localStorage.setItem('hash', result.data.data.hash);
             
              if (Capacitor.getPlatform() === 'ios' || Capacitor.getPlatform() === 'android') {
                const browser = InAppBrowser.create(url, '_blank', 'hidden=no,location=no,clearsessioncache=yes,clearcache=yes');
                browser.on('loadstart').subscribe((e) => {
                  if (e.url.includes('joinup')) {
                    browser.close();
                  }
                });
                this.loading = false;
              } else {
           //     this.loading = false;
                console.log(url);
                window.location.href = url;
              //  this.isValidLink = true;

              }
            } else {
              this.showModalError = true;
              this.loading = false;
            }
          }).catch((e) => {
          
           // this.isValidLink = false;
          });
          this.isLoading = false;

    },

    async resolveRedirect() {

      // let invoice_show_type = this.ownersAssociationId.administration.invoices_show_type;
      if (this.invoices_show_type === 1) {
        this.redirect('comprobantes_landing');
      } else
        if (this.invoices_show_type === 2) this.redirect('documentos_landing');
    },

    async getExpensesData() {
      this.isLoading = true;

      await axios.get(
        `${process.env.VUE_APP_CORE_SERVICE_URL}/expenses/owners_associations/${this.ownersAssociationId}/aws_user_id/${this.userId}/summary`, {
        headers:
        {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
      ).then(async (result) => {

        console.log(result);
        this.ownersAssociation = result.data.ownersAssociation;

        this.period = result.data.period;

        localStorage.setItem('period', JSON.stringify(this.period));
        console.log(JSON.stringify(this.period));
        console.log(localStorage.getItem('period'));

        this.periodUltimo = result.data.period;
        this.allPeriods = result.data.all_periods;
        this.user = result.data.user;
        this.invoices_delay = result.data.invoices_delay;
        console.log(this.period);

        this.invoices_show_type = this.ownersAssociation.invoices_show_type;
        localStorage.setItem('owners_association', JSON.stringify(this.ownersAssociation));
        localStorage.setItem('owners_association_id', this.ownersAssociationId);
        localStorage.setItem('invoices_delay', this.invoices_delay);
        localStorage.setItem('user_id', this.userId);

        localStorage.setItem('invoices_show_type', this.invoices_show_type);
        console.log(`payment_methods_id: ${this.ownersAssociation.expense_payment_method_ids}`);
        if (this.$route.query.period_id || this.$route.query.period_id != this.periodUltimo.period_id) {

          this.changePeriod({ id: this.$route.query.period_id });
        }

      }).catch(() => {
        this.isValidLink = false;
      });
      this.isLoading = false;
    },
    async changePeriod(period) {
      this.isLoading = true;
      let url;
      if (period.id === this.periodUltimo.period_id) {

        url = `${process.env.VUE_APP_CORE_SERVICE_URL}/expenses/owners_associations/${this.ownersAssociationId}/aws_user_id/${this.userId}/summary`;
      } else {
        url = `${process.env.VUE_APP_CORE_SERVICE_URL}/expenses/owners_associations/${this.ownersAssociationId}/aws_user_id/${this.userId}/summary/${period.id}`;
      }

      await axios.get(
        url, {
        headers:
        {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
      ).then((result) => {
        this.ownersAssociation = result.data.ownersAssociation;
        this.period = result.data.period;
        this.allPeriods = result.data.all_periods;
        this.user = result.data.user;
        localStorage.setItem('period', JSON.stringify(this.period));
        localStorage.setItem('owners_association', JSON.stringify(this.ownersAssociation));
        this.isLoading = false;
      }).catch(() => {
        this.isLoading = false;
      });
    },
  },
  async created() {
    console.log('created');
    // localStorage.setItem('User', JSON.stringify({ isIdataUser: false }));

    this.getExpensesData();

  },

  computed:
  {
    periods() {
      return this._.orderBy(this.allPeriods, 'settlement_date', 'desc');
    },
    city_image() {
      const date = this.$moment();
      const season = this.getSeasonByDate(date);

      switch (this.ownersAssociation.city) {
        case 'Mar del Plata':
          return {
            // eslint-disable-next-line global-require,import/no-dynamic-require
            img_normal: require(`@/assets/img/cities/mardelplata_${season}.svg`),
            // eslint-disable-next-line global-require,import/no-dynamic-require
            img_2x: require(`@/assets/img/cities/mardelplata_${season}.svg`),
          };
        case 'Cordoba':
          return {
            // eslint-disable-next-line global-require,import/no-dynamic-require
            img_normal: require(`@/assets/img/cities/cordoba_${season}.svg`),
            // eslint-disable-next-line global-require,import/no-dynamic-require
            img_2x: require(`@/assets/img/cities/cordoba_${season}.svg`),
          };
        case 'Rosario':
          return {
            // eslint-disable-next-line global-require,import/no-dynamic-require
            img_normal: require(`@/assets/img/cities/rosario_${season}.svg`),
            // eslint-disable-next-line global-require,import/no-dynamic-require
            img_2x: require(`@/assets/img/cities/rosario_${season}svg`),
          };
        case 'C.A.B.A.':
          return {
            // eslint-disable-next-line global-require,import/no-dynamic-require
            img_normal: require(`@/assets/img/cities/caba_${season}.svg`),
            // eslint-disable-next-line global-require,import/no-dynamic-require
            img_2x: require(`@/assets/img/cities/caba_${season}.svg`),
          };
        default:
          return {
            // eslint-disable-next-line global-require,import/no-dynamic-require
            img_normal: require(`@/assets/img/cities/generica_${season}.svg`),
            // eslint-disable-next-line global-require,import/no-dynamic-require
            img_2x: require(`@/assets/img/cities/generica_${season}.svg`),
          };
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  min-height: 6em;
}

.header-text {
  font-weight: 300;
  letter-spacing: 0.075em;
  padding: 1em 0;
}

.owners-associations-card {
  padding: .5em 0;
  border-radius: 6px;
  margin-top: 1.5rem;
}

.owners-association-data {
  line-height: -1.25rem;
}

.settlement-card {
  padding: .5em 0;
  border-radius: 6px;
  margin-top: 1.5rem;
  margin-bottom: -5.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 12), 0 1px 2px rgba(0, 0, 0, 24);
  transition: all .3s cubic-bezier(.25, .8, .25, 1);
}

.settlement-month {
  margin-bottom: .5rem;
  font-size: 1.5rem;
  color: #000;
  text-transform: uppercase;
  font-weight: 300;
}

.owners-association-city-image {
  max-width: 98%;
  height: auto;
  display: block;
  z-index: 998;
  margin-left: auto;
  margin-right: auto;
  margin-top: -20px;
}

.settlement-overview {
  display: block;
  margin-top: -5rem;
  z-index: 999;
}

.settlement-data {
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.btnlanding {
  min-width: 300px !important;
}

.owners-associations-name-landing {
  margin-top: .5rem;
  font-size: 1.25rem;
  font-weight: 400;
  color: #000 !important;
}

.owners-associations-address-landing {
  margin-top: -.5rem;
  margin-bottom: .5rem;
  font-size: 1rem;
  font-weight: 300;
  color: #676a69
}

#owners-association-title-landing {
  color: #081A2B;
  margin-top: 0.5em;
  font-weight: 300;
  letter-spacing: .075em;
}

#settlement-actions {
  padding: .75em 1.25em .75em 1.25em;
  justify-content: center;
}

#download_documents,
#download_settlements,#pay,
#view_invoices {
  padding-top: 0.75em !important;
  padding-bottom: 0 !important;
}

#footer-login-text {
  letter-spacing: .075em;
  margin-top: -3.5rem;
  font-weight: 300;
}

#footer-login-btn {
  margin-bottom: -3.5em;
}

.footer {
  position: fixed !important;
  bottom: 0 !important;
  left: 0 !important;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-height: 30vh;
  padding: .5rem 1rem;
  justify-content: center;
  background-color: #081a2b;
  z-index: 9999 !important;
}

.logo {
  width: 100px;
  height: auto;
}
#modal-2 .modal-body {
  height: 100% !important;
}

#modal-2 .modal-footer {
  justify-content: flex-end;
}
@media all and (max-width: 360px) {
  .btn {
    min-width: 1px !important;
  }
}
</style>
