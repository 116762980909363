<template>
  <div id="news-page-container" class="page-account-view page-news-view">
    <div id="news-contents">
      <section id="nav" class="globalnav-desktop" v-if="!logged">
        <div class="container-fluid">
          <nav class="navbar navbar-dark">
              <router-link class="navbar-brand" to="/">
              <img src="@/assets/img/idata-alt.svg" class="logo" alt="">
            </router-link>
            <ul class="nav">
              <li class="nav-item nav-item-account">
                <router-link class="nav-link" to="/">
                  <img src="@/assets/img/icons/account-white.svg" class="icon" alt="">
                  <span>Ingresar</span>
                </router-link>
              </li>
            </ul>
          </nav>
        </div>
      </section>
      <section class="bar theme-blue">
        <div class="container" v-if="no_news">
          <div class="bar-avatar">
            <span><img src="@/assets/img/icono-noticias.svg" class="img_min"></span>
          </div>
          <h3 class="bar-firm-name">{{news.title}}</h3>
          <h5 class="bar-tagline">{{news.category}}</h5>
          <br>
        </div>
        <div class="container" v-else>
          <div class="bar-avatar">
            <span><img src="@/assets/img/icono-noticias.svg" class="img_min"></span>
          </div>
          <h3 class="bar-firm-name">Noticia</h3>
          <br>
        </div>
      </section>
      <div class="container bar-news">
        <vue-element-loading :active="isLoading" spinner="spinner" color="#CE4169" />
        <div class="news-detail shadow" v-if="no_news">
          <p class="news-body" v-html="news.body" />
          <div class="row text-center news-image">
            <div v-for="image in news.images" :key="image">
              <a :href="image.url" data-lightbox="roadtrip" class="d-block mb-4 h-100">
                <img class="img-fluid img-thumbnail" :src="image.thumbnail_xs" alt="">
              </a>
            </div>
          </div>
          <div v-if="news.attachments && news.attachments.length">
            <strong class="ml-1">Adjuntos</strong>
            <div class="mt-3">
            <div class="news-attachments" v-for="attachment in news.attachments" :key="attachment">
              <a :href="attachment.url" @click="downloadFallback(attachment.url)" target="_blank"
                 class="btn btn-white" style="color: #CE4169; margin: 0; padding: 0">
                <img src="@/assets/img/icons/document-alt.svg" class="icon" style="vertical-align: top;"
                     alt="" /> {{attachment.file_name}}
              </a>
            </div>
            </div>
          </div>
        </div>

        <div class="news-detail shadow" v-else>
          <div class="empty-state empty-state-documents" id="no-documents-message" v-if="imgNo">
            <div class="wrapper">
              <h4>¡La noticia no se encuentra disponible en este momento!</h4>
              <p>Vuelve a intentar nuevamente mas tarde, o contacta a tu administración para mas información.
              </p>
              <img src="@/assets/img/empty-states/mensaje-documento-eliminado.png" class="image-empty" alt="">
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'NewsLanding',
  components:
      {
      },
  data() {
    return {
      newsId: null,
      news: {},
      news_type: true,
      isLoading: true,
      imgNo: false,
    };
  },
  created() {
    this.newsId = this.$route.params.id;
    this.getNewsData();
    console.log('id noticia:', this.newsId);
    console.log('this.$route.params:', this.$route.params.id);
  },
  computed: {
    no_news() {
      return !this.isLoading && this.news_type;
    },
    logged() {
      console.log('logged:', this.$store.getters.logged);
      return this.$store.getters.logged;
    },
  },
  methods: {
    async getNewsData() {
      await axios.get(
        `${process.env.VUE_APP_CORE_SERVICE_URL}/news/${this.newsId}`, {
          headers:
                {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                },
        },
      ).then((result) => {
        this.news = result.data.data;
        this.news_type = true;
        this.isLoading = false;
      }).catch(() => {
        this.imgNo = true;
        this.isLoading = false;
        this.news_type = false;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '/assets/lightbox/css/lightbox.css';

#news-page-container{
  display: flex;
  flex-direction: column;
  height: 100vh;
}
#news-contents{
  flex: 1 0 auto;
}

.header{
  min-height: 6em;
}
.header-text{
  font-weight: 300;
  letter-spacing: 0.075em;
  padding: 1em 0;
}

.btn{
  min-width: 300px!important;
}
#footer{
  flex-shrink: 0;
}
#footer-login-text{
  letter-spacing: .075em;
  margin-top: -3.5rem;
  font-weight: 300;
}
#footer-login-btn{
  margin-bottom: -3.5em;
}
</style>
